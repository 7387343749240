<template>
  <div class="maze">
    <div class="canvas">
      <div class="player" :style="{top:`${paperPosition.y * tileSize}px`,left:`${paperPosition.x * tileSize}px`, backgroundImage: 'url(\'/paper.png\')'}"></div>
      <div class="player" :style="{top:`${playerPosition.y * tileSize}px`,left:`${playerPosition.x * tileSize}px`, backgroundImage: 'url(\'/unnamed.png\')'}"></div>
      <template v-for="(row, rowIndex) in map">
        <template v-for="(col, colIndex) in row">
          <div v-if="col === 1" class="wall" :style="{top: `${rowIndex * tileSize}px`, left: `${colIndex * tileSize}px`}" :key="colIndex + '-' + rowIndex"></div>
        </template>
      </template>
    </div>
    <div class="controls">
      <button v-on:click="()=>move(-1,0)" class="left">←</button>
      <button v-on:click="()=>move(1,0)" class="right">→</button>
      <button v-on:click="()=>move(0,-1)" class="top">↑</button>
      <button v-on:click="()=>move(0,1)" class="bottom">↓</button>
      <h1>{{shownCode}}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maze',
  data: () => ({
    map:{},
    tileSize:20,
    shownCode:"",
    code:"423",
    playerPosition:{
      x:1,
      y:1
    },
    paperPosition:{
      x:1,
      y:1
    }
  }),
  created() {
    this.axios.get("/map.json").then((json)=>{
      this.map = json.data

      for(let i = 0;i<this.map.length;i++){
        for(let j = 0;j<this.map[i].length;j++){
          if(this.map[i][j] === 2){
            this.paperPosition = {x: j, y: i}
          }else if(this.map[i][j] === 3){
            this.playerPosition = {x: j, y: i}
          }
        }
      }
    })
  },
  methods:{
    move(x,y){
      let newX = this.playerPosition.x + x;
      let newY = this.playerPosition.y + y;

      if(this.map[newY][newX] !== 1){
        this.playerPosition.x = newX;
        this.playerPosition.y = newY;
      }

      if(this.playerPosition.x === this.paperPosition.x && this.playerPosition.y === this.paperPosition.y){
        this.shownCode = this.code;
      }else{
        this.shownCode = "";
      }
    }
  }
}
</script>

<style scoped>
  h1{
    display:inline;
    padding:10px;
  }
  .controls button{
    font-size: 30px;
  }

  .canvas{
    width: 500px;
    height: 500px;
    position: relative;
    background-color: #a86b50;
  }

  .wall{
    background-color: brown;
    position:absolute;
    width: 20px;
    height: 20px;
  }

  .player{
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: contain;
  }
</style>
