<template>
  <Maze/>
</template>

<script>
import Maze from './components/Maze.vue'

export default {
  name: 'App',
  components: {
    Maze
  }
}
</script>

<style>

</style>
